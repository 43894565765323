//////////////////////////////////////////////
//
// Contact Form Method
//
//////////////////////////////////////////////

//Validate Emails
function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

// Validate and submit form data
document.addEventListener("DOMContentLoaded", function() {

	const formElem = document.querySelector("#contactForm");

	if (formElem != null) {
		formElem.addEventListener('submit', (event) => {

			event.preventDefault();

			let data = new FormData(formElem);

			let errorClass = document.querySelectorAll(".errored")
			errorClass.forEach(function(error) {
				error.classList.remove("errored");
			});

			let errors = false;

			let validation = {
				name: data.get("fromName"),
				email: data.get("fromEmail"),
				subject: data.get("subject"),
				message: data.get("message[Comment]")
			};

			if (validation.name == "") {
				errors = true;
				document.querySelector("#fromName").classList.add("errored")
			};

			if (validation.email == "" || validateEmail(validation.email) != true) {
				errors = true;
				document.querySelector("#fromEmail").classList.add("errored")
			};

			if (validation.message == "") {
				errors = true;
				document.querySelector("#message").classList.add("errored")
			};

			if (!errors) {

				let message = document.querySelector(".contact-form-success");
				let xhrRequest = new XMLHttpRequest();

				xhrRequest.open("POST", "/", true);

				xhrRequest.setRequestHeader("Accept", "application/json");

				xhrRequest.onload = function(event) {
					if (xhrRequest.status == 200) {
						formElem.classList.add('hidden');
						message.classList.remove('hidden');
					} else {
						message.innerHTML = "Error " + xhrRequest.status + " occurred when trying to submit your form.<br \/>";
					}
				};
				xhrRequest.send(data);
			}
		}, false);
	}
})